#demo {
	background: #fff;
	height: 100%;
	padding: 50px;
}

.gameo-editor,
.igloo-editor {
	label {
		margin-bottom: 0.1rem;
		font-size: 0.9rem;
		color: #000;
	}

	.help-block {
		font-size: 12px;
	}

	.gameo-tabs.tabs,
	.igloo-tabs.tabs {
		margin: 25px auto;
	}

	.gameo-remove-item,
	.igloo-remove-item {
		position: relative;
		z-index: 99;
	}

	.gameo-undo,
	.igloo-undo {
		margin-left: 8px;
		margin-right: 8px;
	}

	.gameo-input-group,
	.igloo-input-group {
		margin-top: 10px;
		margin-bottom: 10px;
		position: relative;

		.gameo-input,
		.igloo-input {
			display: block;
			padding-left: 10px;
			height: 50px;
			line-height: 50px;
			width: 100%;
			margin-top: 10px;
			box-shadow: none;
			border: none;
			background: #ebebeb;
		}
	}

	.gameo-well,
	.igloo-well {
		background: #f6f6f6;
		padding: 50px;
		height: 100%;
		overflow: hidden;
	}

	.ql-insertImage {
		.ql-picker-item {
			&::after {
				content: attr(data-label);
			}
		}

		.ql-picker-label {
			&::after {
				font-family: "Font Awesome 5 Free";
				content: "\f03e";
				padding-right: 20px;
				font-size: 18px;
			}
		}
	}

	.react-tabs {
		.react-tabs__tab {
			border: none;
			padding: 8px 12px;
			margin-right: 8px;
			font-size: 20px;
		}

		.react-tabs__tab--selected {
			border-bottom: 4px solid #4b7df3;
		}
	}
}
