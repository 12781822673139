@import url("https://fonts.googleapis.com/css?family=Lato:300,400,700,900");

$primary: #4b7df3;
$success: #3fb86b;
$secondary: #595965;

$font-family-base: "Lato", sans-serif;
$fa-font-path: "~@fortawesome/fontawesome-free/webfonts";

@import "~@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "~@fortawesome/fontawesome-free/scss/solid.scss";
@import "~@fortawesome/fontawesome-free/scss/regular.scss";
@import "~@fortawesome/fontawesome-free/scss/brands.scss";

#gameo,
#igloo,
.gameo-bootstrap,
.igloo-bootstrap {
	@import "~bootstrap/scss/bootstrap.scss";

	margin: 0;
	font-family: $font-family-base;
	font-size: 16px;
	color: $body-color;
	text-align: left;
	background-color: transparent;

	.btn-primary {
		color: #fff !important;
	}

	label {
		font-size: 0.8rem;
		color: #000000;
		margin-bottom: 0.3rem;
	}

	table,
	td {
		border: 1px solid #000;
	}

	table {
		width: 100%;

		td {
			padding: 5px 10px;
		}
	}
}
