.tag-filter-dropdown {
	.dropdown-divider {
		margin: 0.3rem 0;
		border-color: #f9f9f9;
	}

	button:hover {
		text-decoration: none;
	}

	.filter-button {
		color: #98999b;

		span.filter-text {
			display: block;
			margin-top: -2px;
			font-size: 14px;
			text-transform: uppercase;
		}
	}

	.dropdown-item {
		font-weight: 300;
	}

	.tag-icon-default {
		margin-right: 10px;
		color: #4c4c4c;
	}

	.tag-icon-selected {
		margin-right: 10px;
		color: #3fb86b;
	}
}
