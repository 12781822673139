@import "../ui/common.scss";

.page-login {
	.login-container {
		width: 50%;
		height: 100%;
		margin-top: 100px;

		@media (max-width: 768px) {
			width: 100%;
			padding: 0;
			margin-top: 0;
		}

		.frow {
			padding: 40px;
			margin-bottom: 100px;
			background: #fff;
			border-bottom: 5px solid $primary;

			@media (max-width: 768px) {
				width: 100%;
				padding: 10px;
				padding-bottom: 40px;
				margin-right: 0;
				margin-bottom: 0;
				margin-left: 0;
			}
		}

		.pwd-wrapper {
			margin-top: 20px;
		}

		h1 {
			margin-bottom: 40px;
			font-size: 2.5vw;
			font-weight: 300;
			color: #666;

			@media (max-width: 768px) {
				margin-top: 20px;
				font-size: 26px;
			}
		}

		input[type="text"],
		input[type="password"] {
			-webkit-box-sizing: border-box;
			-moz-box-sizing: border-box;
			box-sizing: border-box;
			height: 45px;
			padding: 10px;
			font-size: 16px;
			border: 1px solid #ebebeb;
		}

		.button button {
			float: right;
			padding: 10px 30px;
			margin-top: 20px;
			color: #fff;
			border: none;
		}

		label {
			display: contents;
			margin-bottom: 10px;
			font-size: 1rem;
			color: #000000;
		}
	}

	.igloo-login-nav {
		justify-content: flex-end;
		width: 100%;
		.btn-rounded {
			width: 130px;
			margin-right: 15px;
			color: #fff;
			letter-spacing: 1px;
			border: 1px solid #fff;

			&.active {
				color: $secondary !important;
				background: #fff;
			}
		}

		@media (max-width: 736px) {
			margin-top: 20px;
			margin-bottom: 40px;
			margin-left: 20px;
		}
	}
}
