@import "../ui/common.scss";

#page-home {
	background: #fff;
	.igloo-homepage-header {
		position: relative;
		z-index: 10;
		height: 120px;
		padding-right: 60px;
		padding-left: 10%;

		@media (max-width: 1366px) {
			padding-left: 4%;
		}

		@media (max-width: 900px) {
			padding-left: 5%;
			padding-right: 3%;
		}

		@media (max-width: 768px) {
			padding-left: 4%;
		}

		div {
			height: 160px;
			@media (max-width: 736px) {
				height: auto;
			}
		}

		.igloo-logo {
			margin-top: 40px;

			@media (max-width: 780px) {
				padding-right: 0;
			}

			@media (max-width: 767px) {
				svg path {
					fill: #ffffff;
				}

				.igloo-logo-text {
					color: #ffffff;
				}
			}

			@media (max-width: 736px) {
				margin-top: 60px;
				display: block;
				text-align: center;

				.igloo-logo-text {
					display: none;
				}
			}

			@media (max-width: 673px) {
				margin-top: 60px;
			}
		}

		.igloo-homepage-header-nav {
			justify-content: flex-end;
			padding-left: 0;

			.btn-rounded {
				width: 130px;
				margin-right: 15px;
				color: #fff;
				letter-spacing: 1px;
				border: 1px solid #fff;

				@media (max-width: 900px) {
					margin-right: 8px;
				}

				@media (max-width: 768px) {
					margin: 2px;
				}

				&.btn-premium {
					background: #3fb86b;
					border: 0;
					border: 1px solid #3fb86b;
					padding: 10px 5px;
					font-weight: 600;
				}

				&.btn-premium:hover {
					background: #ffffff;
					border: 1px solid #ffffff;
					color: #3fb86b;
				}

				&.btn-regular:hover {
					background: #ffffff;
					border: 1px solid #ffffff;
					color: $secondary;
				}
			}

			@media (max-width: 736px) {
				margin-top: 40px;
				margin-bottom: 40px;
				display: block;
				text-align: center;
				width: 100%;
				padding: 0px 40px;
			}

			@media (max-width: 485px) {
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
			}
		}

		@media (max-width: 767px) {
			@include commonGradient();

			height: auto;
			max-height: auto;
			margin-bottom: 0;
		}
	}

	.igloo-intro-section {
		margin-top: -120px;

		.igloo-demo {
			min-height: 850px;
			max-height: 850px;
			padding: 200px 15px 0 5%;
			background-repeat: no-repeat;
			background-position: right;
			background-size: contain;
			@include commonGradient();

			@media (max-width: 736px) {
				min-height: 700px;
				padding: 50px 15px 0 5%;
			}

			.accounting-image {
				position: absolute;
				right: 0;
				bottom: -5.8vw;
			}
		}

		.igloo-intro {
			padding: 200px 5% 5% 10%;
			padding-top: 200px;

			@media (max-width: 736px) {
				text-align: center;
			}

			@media (max-width: 1366px) {
				padding: 200px 2% 2% 4%;
			}

			p {
				max-width: 85%;
				font-size: 26px;
				font-weight: 300;
				line-height: 1.3;
				color: grey;

				@media (max-width: 1024px) {
					max-width: 100%;
					font-size: 24px;
				}
			}

			h1 {
				margin-top: 30px;
				margin-bottom: 30px;
				font-size: 56px;
				font-weight: 700;
				line-height: 56px;
				color: $secondary;

				@media (max-width: 1024px) {
					font-size: 38px;
					line-height: 38px;
				}
			}

			.igloo-start-btn {
				position: relative;
				z-index: 10;
				display: block;
				width: 140%;
				padding-top: 40px;
				padding-bottom: 40px;
				margin-top: 60px;
				margin-left: -75px;
				font-size: 40px;
				font-weight: 300;
				color: #ffffff;
				text-align: center;
				text-transform: uppercase;
				background: #282866;
				border-radius: 90px;
				border: 5px solid #282866;
				-webkit-box-shadow: -2px -2px 67px -5px
					rgba(255, 255, 255, 0.75);
				-moz-box-shadow: -2px -2px 67px -5px rgba(255, 255, 255, 0.75);
				box-shadow: -2px -2px 67px -5px rgba(255, 255, 255, 0.75);

				&:hover {
					text-decoration: none;
					background-color: #ffffff;
					color: #282866;
					border: 5px solid #282866;
					transition: 0.2s;
					-webkit-box-shadow: -2px -2px 67px -5px
						rgba(255, 255, 255, 0.75);
					-moz-box-shadow: -2px -2px 67px -5px
						rgba(255, 255, 255, 0.75);
					box-shadow: -2px -2px 67px -5px rgba(255, 255, 255, 0.75);
				}

				@media (max-width: 1366px) {
					width: 100%;
					margin-left: 0;
				}

				@media (max-width: 900px) {
					padding: 30px 0px;
					font-size: 30px;
				}

				@media (max-width: 736px) {
					width: 90%;
					margin: 50px auto;
					margin-bottom: 50px;
				}
			}
		}
	}

	.igloo-library-section {
		padding-top: 80px;
		padding-bottom: 80px;
		text-align: center;

		@media (max-width: 768px) {
			min-height: 350px;
			padding-top: 40px;
			padding-bottom: 40px;
		}

		h2 {
			max-width: 40vw;
			margin: 0 auto;
			color: $secondary;

			@media (max-width: 1366px) {
				max-width: 60vw;
			}

			@media (max-width: 768px) {
				max-width: 90vw;
			}
		}

		.igloo-library-section-button {
			display: block;
			max-width: 310px;
			padding: 16px 60px;
			margin: 40px auto;
			font-size: 20px;
			font-weight: 400;
			color: #fff;
			border: 2px solid #282866;
			border-radius: 90px;
			background-color: $secondary;
			text-decoration: none;
		}

		.igloo-library-section-button:hover {
			border: 2px solid #282866;
			color: #282866;
			background-color: #ffffff;
			transition: 0.2s;
		}
	}

	.igloo-app-screen {
		margin-bottom: 100px;

		.app-image {
			max-width: 70%;

			@media (max-width: 1000px) {
				max-width: 80%;
			}

			@media (max-width: 768px) {
				max-width: 90%;
			}
		}
	}

	.igloo-steps-section {
		padding-top: 10px;
		h4 {
			margin-bottom: 30px;
			font-size: 36px;
			color: #142263;
			text-align: center;
		}

		.igloo-steps-img {
			padding-top: 50px;
			padding-bottom: 50px;

			@media (max-width: 768px) {
				padding: 20px 0px;

				.img-padding {
					padding-bottom: 70px;
				}
			}

			strong {
				display: block;
				margin-bottom: 20px;
				color: #000000;
				font-size: 1.5em;
				font-weight: 600;
			}

			p {
				max-width: 320px;
				margin: 0 auto;
				color: #636362;
			}

			div {
				margin-bottom: 20px;

				@media (max-width: 768px) {
					min-height: 250px;
					margin-bottom: 10px;
				}
			}

			img {
				display: block;
				max-height: 250px;
				margin: 0 auto;
				margin-bottom: 25px;

				@media (max-width: 500px) {
					max-height: auto;
					max-width: 300px;
				}
			}

			.igloo-steps-section-button {
				display: block;
				max-width: 310px;
				padding: 16px 60px;
				margin: 40px auto;
				font-size: 20px;
				font-weight: 400;
				color: #fff;
				border: 2px solid #282866;
				border-radius: 90px;
				background-color: $secondary;
				text-decoration: none;
			}

			.igloo-steps-section-button:hover {
				border: 2px solid #282866;
				color: #282866;
				background-color: #ffffff;
				transition: 0.2s;
			}
		}
	}

	.igloo-examples-section {
		.examples-pane {
			background-color: $secondary;
			min-height: 600px;
			padding: 40px 0;
			font-size: 1.8rem;
			font-weight: 300;
			color: #ffffff;

			p {
				text-align: left;
				margin-left: 10vw;
				margin-right: 6vw;
			}

			.igloo-examples-table {
				display: flex;
				justify-content: flex-end;
				margin-right: -3px;
				margin-left: 5%;

				@media (max-width: 768px) {
					justify-content: center;
				}

				table {
					border: 2px solid #57b4c9;
					margin-top: 3%;
					width: 85%;

					td {
						border: 2px solid #57b4c9;
					}

					td a {
						text-decoration: none;
						color: #ffffff;
					}
				}

				.number-cell {
					padding: 25px 15px;
					width: 5%;
				}

				.text-cell {
					text-align: right;
					padding-right: 10%;
					width: 90%;
				}
			}
		}

		.examples-pane-button {
			justify-content: flex-end;
			margin-right: 8%;

			@media (max-width: 768px) {
				justify-content: center;
				margin-right: 0;
			}

			.igloo-examples-section-button {
				display: block;
				max-width: 210px;
				padding: 16px 40px;
				margin: 40px 0px;
				font-size: 20px;
				font-weight: 400;
				color: #fff;
				border: 1px solid #ffffff;
				border-radius: 90px;
				background-color: $secondary;
				text-decoration: none;
			}

			.igloo-examples-section-button:hover {
				border: 2px solid #282866;
				color: #282866;
				background-color: #ffffff;
				border: 1px solid #ffffff;
				transition: 0.2s;
			}
		}

		.interaction-pane {
			background-color: #57b4c9;

			.gameo-dnd {
				width: 70%;
				padding: 40px 0;
				margin-left: 6vw;

				strong {
					font-size: 20px !important;
					line-height: 2;
				}
			}
		}
	}

	.igloo-features-section {
		padding-top: 100px;

		@media (max-width: 768px) {
			padding-top: 30px;
		}

		.features-description {
			padding: 60px 10%;
			align-items: center;

			.features-description-text {
				min-width: 500px;

				@media (max-width: 1077px) {
					padding-top: 50px;
				}

				@media (max-width: 768px) {
					min-width: 50%;
				}

				strong {
					font-size: 30px;
					font-weight: 600;
					color: #000000;
				}

				p {
					font-size: 23px;
					font-weight: 300;
				}
			}
		}

		@media (max-width: 1077px) {
			.row-invert-mobile {
				display: flex;
				flex-direction: column-reverse;
			}
		}

		img {
			display: block;
			max-height: 300px;
			margin: 0 auto;

			@media (max-width: 500px) {
				max-width: 250px;
			}
		}
	}
}
