.page-my-team {
	.my-team-container {
		h3 {
			margin-bottom: 30px;
			font-weight: 300;
			text-transform: uppercase;
		}

		.my-team-members {
			padding: 30px;
			margin-top: 40px;
			background: #fff;
		}

		.my-team-invite {
			padding: 30px;
			margin-top: 40px;
			background: #fff;
			border-bottom: 5px solid #2e88ed;

			h3 {
				margin-bottom: 10px;
			}

			p {
				color: #666;
			}
		}
	}
}
