.form-search {
	.input-group {
		padding-left: 20px;
		background: #fff;
		border-radius: 25px;
		padding-top: 3px;
		padding-bottom: 3px;
		svg {
			width: 0.9em;
			color: #686671;
		}
	}
}
