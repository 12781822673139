.navbar.main-nav {
	height: 125px;
	box-shadow: none;

	ul {
		margin-right: 5%;
	}

	.header-row {
		width: 100%;
	}

	.navbar-brand {
		padding-left: 5%;

		img {
			max-height: 65px;
			margin-top: -14px;
		}

		span {
			display: inline-block;
			margin-top: 3px;
			margin-left: 15px;
			font-size: 36px;
			font-weight: 300;
			color: #fff;
		}

		a:hover {
			text-decoration: none;
		}
	}

	.btn-white-rounded {
		float: right;
		padding: 10px 50px;
		font-size: 16px;
		font-weight: 300;
		color: #5c5b60;
		text-transform: uppercase;
		border: none;
		border-radius: 30px;
	}

	a.btn-rounded {
		margin-left: 20px;
		color: #fff !important;
	}
}

.header-loggedIn {
	height: 65px;
	padding-right: 50px;
	padding-left: 50px;
	line-height: 65px;
	background: #fff;

	svg {
		cursor: pointer;
	}

	.header-username {
		float: right;
	}

	.page-title {
		margin-left: 30px;
		font-size: 18px;
		text-transform: uppercase;
	}

	.premium-link {
		font-weight: bold;
		margin-right: 20px;
	}
}
