#page-preview-interaction {
	.preview-header-h1 {
		font-size: 28px;
		font-weight: 300;
		color: #fff;

		@media (max-width: 768px) {
			font-size: 18px;
		}
	}

	.preview-info {
		background: #ebebeb;
		padding: 25px 0 20px 0;
		.preview-header {
			font-weight: 700;
		}
	}

	footer {
		margin-top: 100px;
	}

	.header-row {
		.vertical-align:nth-child(2) {
			display: block;

			.interaction-categories {
				display: block;
				font-size: 12px;
				color: #fff;
				text-transform: uppercase;
			}
		}
	}
}
