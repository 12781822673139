@import "../ui/common.scss";

.page-dashboard {
	.user-welcome {
		display: block;
		font-size: 28px;
		font-weight: 300;
		color: #fff;

		@media (max-width: 1199px) {
			font-size: 24px;
		}

		@media (max-width: 992px) {
			font-size: 16px;
		}
	}

	.user-create-interaction {
		font-size: 14px;
		font-weight: 300;
		color: #fff;
		@media (max-width: 1199px) {
			font-size: 12px;
		}
	}

	.dashboard-stats-container {
		margin-bottom: 30px;
		background: #fff;
		border-bottom: 1px solid #e6e7eb;

		.dashboard-stat-container {
			padding-top: 50px;
			padding-bottom: 50px;
			.dashboard-stat {
				display: block;
				font-size: 36px;
				font-weight: 300;
				line-height: 40px;
				color: #595764;

				@media (max-width: 992px) {
					font-size: 28px;
				}
			}

			.dashboard-stat-title {
				display: block;
				font-size: 20px;
				font-weight: 300;
				line-height: 26px;
				color: #595764;

				@media (max-width: 992px) {
					font-size: 18px;
				}
			}

			.dashboard-stat-description {
				display: block;
				font-size: 16px;
				font-weight: 300;
				color: #b6b6b6;

				@media (max-width: 992px) {
					font-size: 12px;
				}
			}

			.btn-rounded {
				padding: 8px 40px;
				margin-top: 20px;
				text-transform: uppercase;
			}

			@media (max-width: 992px) {
				border-right: none;
			}
		}

		.dashboard-right-stats {
			span {
				display: block;
				margin-bottom: 8px;
				font-size: 20px;
				font-weight: 300;
				color: #5e5c6a;
			}
		}
	}

	.header-rounded-inputs {
		margin-top: 40px;
		margin-bottom: 60px;

		h2 {
			font-size: 24px;
			font-weight: 300;
			text-transform: uppercase;
		}

		.selectWrapper {
			padding-top: 3px;
			padding-bottom: 3px;
		}

		@media (max-width: 768px) {
			.selectWrapper {
				margin-top: 20px;
			}
		}
	}

	.dashboard-filters {
		.btn-link {
			color: #97989c;
			&:hover {
				color: darken(#97989c, 20%);
				text-decoration: none;
			}
		}
		.filter-button {
			span {
				display: block;
				margin-top: -5px;
				text-decoration: uppercase;
			}
		}
	}

	.interaction-list-row {
		.interaction-options-link {
			width: 1em;
			height: 45px;
			margin-left: 15px;
			color: #adacb2;
		}

		.igloo-dropdown-with-caret {
			display: inline;
			float: right;
			button {
				padding: 0;
				margin: 0;
				margin-top: -3px;
			}

			.dropdown-item {
				font-weight: 300;
				color: #59585d;
				svg {
					float: right;
					margin-top: 3px;
					color: #59585d;
				}

				.float-right {
					margin-top: 3px;
				}
			}
		}
	}

	.no-interactions {
		margin-top: 40px;
		margin-bottom: 60px;
		font-size: 36px;

		p {
			font-weight: bold;
		}

		.btn {
			margin-top: 30px;
			font-size: 22px;
		}
	}
}
