@keyframes slideInUp {
	from {
		visibility: visible;
		transform: translate3d(0, 100%, 0);
	}

	to {
		transform: translate3d(0, 0, 0);
	}
}

@keyframes slideDownOut {
	from {
		visibility: visible;
		transform: translate3d(0, 0, 0);
	}

	to {
		visibility: hidden;
		transform: translate3d(0, 100%, 0);
	}
}

.slideDownOut {
	animation-name: slideInUp;
	animation-duration: 1s;
}

.slideInUp {
	animation-name: slideInUp;
	animation-duration: 1s;
}

.customModal {
	position: fixed;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1050;
	padding: 30px;
	margin: 0 auto;
	background-color: #fafafa;
}

.customModalBackdrop {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1060;
	padding: 50px;
	background-color: rgba(0, 0, 0, 0.5);
}
