@import "../ui/common.scss";

#library-page {
	.btn-rounded {
		margin-right: 15px;
		color: #ffffff;
		border: 1px solid #ffffff;

		&.active {
			color: $primary;
			background: #142263;
		}
	}

	.igloo-login-nav {
		justify-content: flex-end;
		width: 100%;
		.btn-rounded {
			width: 130px;
			margin-right: 15px;
			color: #fff;
			letter-spacing: 1px;
			border: 1px solid #fff;

			&.active {
				color: $secondary !important;
				background: #fff;
			}
		}
	}

	.igloo-lib-dflt-Interactions {
		padding-top: 120px;
		padding-bottom: 100px;
		background-color: #ffffff;

		.igloo-lib-dflt-interactionrow {
			padding-top: 90px;

			.igloo-lib-dflt-interaction {
				padding-top: 150px;
			}
		}
	}

	.igloo-lib-dflt-name {
		font-weight: bold;
		padding-top: 15px;
		font-size: 22px;
	}

	.igloo-lib-dflt-description {
		display: block;
		padding-top: 10px;
		font-size: 12px;
	}
}
