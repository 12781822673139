.page-public-interactions {
	h1 {
		margin-top: 40px;
		margin-bottom: 40px;
		font-size: 26px;
		font-weight: 300;
		text-transform: uppercase;
	}

	.public-interaction-item {
		padding-bottom: 40px;
		margin-top: 40px;
		margin-bottom: 20px;
		font-size: 14px;
		color: #595961;
		border-bottom: 1px solid #e6e7eb;

		.public-interaction-item-button {
			padding: 5px 18px;
			font-size: 12px;
			font-weight: 300;
			text-transform: uppercase;
			border-radius: 20px;
		}

		.interaction-categories {
			font-size: 12px;
		}
	}
}
