.interaction-list-row {
	padding: 20px;
	margin-bottom: 15px;
	background: #fff;
	border-radius: 10px;

	.button-group {
		width: 100%;
		margin-right: auto;
		margin-left: auto;
	}

	button {
		margin-left: 15px;
	}

	.interaction-list-image {
		flex-shrink: 0;
	}
}
