.page-edit-interaction {
	min-height: 100vh;
	background: #fff;

	.toolbar {
		padding: 25px 15px 10px 30px;
		clear: both;
	}

	.customModal {
		h4 {
			margin: 1.14rem 0 0.912rem 0;
			font-size: 2.28rem;
			font-weight: 400;
			line-height: 110%;
		}

		p,
		strong {
			display: block;
			margin-bottom: 15px;
			font-weight: 300;
			line-height: 1.5;
		}

		#embed-code {
			height: 75px;
			padding: 15px;
			resize: none;
			background: rgba(0, 0, 0, 0.08);
			background-color: transparent;
			border: 0;
		}
	}

	.interaction-tags-wrapper {
		padding: 60px;
		.react-tagsinput {
			background: transparent;
			border: none;

			.react-tagsinput-input {
				width: 400px;
				height: 50px;
				padding-left: 20px;
				line-height: 50px;
				background: #fff;
				border-radius: 25px;
			}

			.tags-heading {
				display: block;
				margin-bottom: 30px;
				font-size: 22px;
				font-weight: 300;
				color: #54575e;
				text-transform: uppercase;
			}

			.tags-label {
				margin-right: 40px;
				font-weight: 300;
				color: #878b8e;
				text-transform: uppercase;
			}

			.react-tagsinput-tag {
				padding: 10px 30px;
				margin-top: 14px;
				margin-right: 14px;
				font-family: inherit;
				font-size: 14px;
				font-weight: 300;
				color: #fff;
				text-align: center;
				text-transform: uppercase;
				background-color: #595965;
				border: none;
				border-radius: 20px;

				.react-tagsinput-remove {
					margin-left: 8px;
					font-weight: 300;
				}
			}
		}
	}

	.interaction-media-wrapper {
		.media-heading {
			display: block;
			font-size: 22px;
			color: #000;
			text-align: center;
		}
	}
}
