.media-item {
	position: relative;
	display: flex;
	width: 32%;
	margin-right: 15px;
	margin-bottom: 15px;
	cursor: pointer;
	background: #fff;

	.card-thumbnail {
		display: flex;
		align-content: center;
		justify-content: center;
		height: 150px;
		background: url("./mediabg.png");

		img {
			align-self: center;
			max-width: 100%;
			max-height: 100%;
		}
	}

	.card-title {
		font-size: 14px;
		font-weight: bold;
	}

	.card-footer {
		font-size: 12px;
	}
}
