.page-media {
	background: #fff;
	.header-row {
		h2 {
			color: #fff;
		}
	}

	.media-list {
		margin-top: 40px;
	}
}
