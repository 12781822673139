@import "../ui/common.scss";

#page-privacy {
	h1 {
		margin-top: 40px;
		margin-bottom: 20px;
		font-weight: 800;
	}

	h3 {
		margin-top: 20px;
		margin-bottom: 20px;
		font-size: 22px;
		font-weight: 700;
	}

	p {
		font-size: 18px;
		font-weight: 300;
		line-height: 26px;
	}
}
