// Buttons

@import url("https://fonts.googleapis.com/css?family=Assistant:200,300,400,600,700,800");

$primary: #2e88ed;
$success: #3fb86b;
$secondary: #282866;

$font-family-base: "Assistant", sans-serif;

@import "~bootstrap/scss/bootstrap.scss";

@mixin commonGradient {
	/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#76d9ec+0,5babf5+50,5babf5+51,3f78fc+100 */
	background: #76d9ec; /* Old browsers */
	background: -moz-linear-gradient(
		45deg,
		#76d9ec 0%,
		#5babf5 50%,
		#5babf5 51%,
		#3f78fc 100%
	); /* FF3.6-15 */
	background: -webkit-linear-gradient(
		45deg,
		#76d9ec 0%,
		#5babf5 50%,
		#5babf5 51%,
		#3f78fc 100%
	); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(
		45deg,
		#76d9ec 0%,
		#5babf5 50%,
		#5babf5 51%,
		#3f78fc 100%
	); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:dximagetransform.microsoft.gradient( startColorstr='#76d9ec', endColorstr='#3f78fc',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}

body {
	background: #f0f4f7;
}

.btn-primary {
	color: #fff !important;
}

.igloo-logo {
	max-height: 80px;
	font-size: 48px;
	font-weight: 500;
	color: $secondary;
	@media (max-width: 736px) {
		margin: 20px 45px;
	}
}

label {
	margin-bottom: 0.3rem;
	font-size: 1rem;
	color: #000000;
}

.btn-rounded {
	padding: 10px 30px;
	font-size: 14px;
	font-weight: 300;
	border: none;
	border-radius: 30px;
}

.common-gradient {
	@include commonGradient();
}

.gradient-border {
	border-style: solid;
	border-width: 1px;
	border-left: none;
	-webkit-border-image: -webkit-gradient(
			linear,
			0 100%,
			0 0,
			from(#e7e7e7),
			to(rgba(0, 0, 0, 0))
		)
		1 100%;
	-webkit-border-image: -webkit-linear-gradient(
			bottom,
			#e7e7e7,
			rgba(0, 0, 0, 0)
		)
		1 100%;
	-moz-border-image: -moz-linear-gradient(bottom, #e7e7e7, rgba(0, 0, 0, 0)) 1
		100%;
	-o-border-image: -o-linear-gradient(bottom, #e7e7e7, rgba(0, 0, 0, 0)) 1
		100%;
	border-image: linear-gradient(to top, #e7e7e7, rgba(0, 0, 0, 0)) 1 100%;
}

.blue-text {
	color: $secondary;
}

.help-text {
	font-size: 12px;
	color: #666;
}

.vertical-align {
	display: flex !important;
	align-items: center;
}

.flex-end {
	justify-content: flex-end;
}

.header-rounded-inputs {
	.form-group {
		margin-bottom: 0;
	}

	.form-control {
		&:focus {
			box-shadow: none;
		}
	}

	input {
		padding-left: 12px;
		font-weight: 300;
		border: 0;
		border-radius: 25px;
	}

	.selectWrapper {
		width: 100%;
		background-color: #fff;
		border-radius: 50px;

		select {
			width: 90%;
			margin: 0 auto;
			font-weight: 300;
			border: 0;
			outline: none;
		}
	}
}

.common-loading {
	padding: 100px;
	font-size: 48px;
	font-weight: 800;
	text-align: center;

	span {
		margin-left: 20px;
		@media (max-width: 768px) {
			display: block;
			margin-top: 20px;
			margin-left: 0;
		}
	}

	@media (max-width: 768px) {
		padding: 0;
		margin-top: 40px;
		margin-bottom: 40px;
		text-align: center;
	}
}
