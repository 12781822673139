$footer-link-color: #727385;

.igloo-footer {
	padding-top: 40px;
	padding-bottom: 40px;
	color: #fff;
	background-color: #181c39;

	@media (max-width: 736px) {
		margin-top: 0;
	}

	.footer-intro-section {
		h5 {
			display: block;
			max-width: 400px;
			font-size: 36px;
			font-weight: 700;

			@media (max-width: 768px) {
				max-width: 100%;
				text-align: center;
			}
		}

		p {
			max-width: 375px;
			margin-top: 18px;
			font-weight: 300;
			@media (max-width: 768px) {
				max-width: 100%;
				text-align: center;
			}
		}

		.footer-get-started {
			justify-content: flex-end;
			a {
				padding: 10px 40px;
				font-weight: 700;
				color: #fff;
				text-transform: uppercase;
				letter-spacing: 1px;
			}

			@media (max-width: 768px) {
				display: block;
				margin-top: 20px;
				text-align: center;
			}
		}
	}

	.footer-nav-section {
		padding-top: 60px;
		padding-bottom: 60px;
		margin-top: 60px;
		border-top: 1px solid #242843;

		.footer-logo-icon {
			@media (max-width: 768px) {
				text-align: center;
				padding-bottom: 30px;
			}
		}

		@media (max-width: 768px) {
			padding-top: 20px;
			margin-top: 20px;
		}

		img {
			max-width: 200px;
			@media (max-width: 768px) {
				display: block;
				margin: 0 auto 20px auto;
				text-align: center;
			}
		}

		.footer-nav-block {
			strong {
				display: block;
				margin-bottom: 25px;
				font-size: 18px;
				font-weight: 300;
				text-transform: uppercase;

				@media (max-width: 768px) {
					text-align: center;
				}
			}

			ul {
				padding: 0;
				margin: 0;
				list-style-type: none;

				li {
					a {
						display: block;
						padding: 0 0 10px 0;
						color: $footer-link-color;
					}

					.btn-link {
						padding: 0 0 10px 0;
						color: $footer-link-color;
					}

					@media (max-width: 768px) {
						text-align: center;
					}
				}
			}
		}

		.footer-social {
			a {
				margin-left: 20px;
				font-size: 22px;
				color: $footer-link-color;
			}

			@media (max-width: 768px) {
				margin-top: 30px;
				text-align: center !important;
			}

			svg {
				@media (max-width: 768px) {
					width: 35px;
					height: 35px;
				}
			}
		}
	}
}
