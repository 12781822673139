.gameo-dnd {
	display: inline-block;
	margin: 0 auto;
	width: 100%;

	& *,
	& *:before,
	& *:after {
		box-sizing: border-box;
	}

	.dnd-item {
		text-align: center;
		border: 1px solid black;
		display: flex;
		padding: 10px;
		margin-bottom: 20px;
		align-items: center;
		justify-content: center;
		width: 100%;
		line-height: initial;
	}

	.gameo-dnd-drag-items {
		z-index: 99;

		.dnd-item {
			position: relative;
		}
	}
}
