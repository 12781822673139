.page-create-interaction {
	.create-interaction-container {
		padding: 20px;
		margin-top: 20px;

		h1 {
			margin-top: 20px;
			margin-bottom: 40px;
			font-size: 26px;
			font-weight: 300;
			text-transform: uppercase;
		}
	}
}
