.bm-menu {
	padding: 40px 60px;
	width: 100%;
	height: 100%;
	a,
	button {
		padding: 0;
		margin-top: 15px;
		font-size: 22px;
		font-weight: 700;
		color: #fff;

		&:hover {
			color: #ebebeb;
		}
	}

	.bm-item-list {
		height: auto !important;
	}
}
