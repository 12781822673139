.media-dropzone {
	display: flex;
	justify-content: space-between;
	margin-top: 40px;
	margin-bottom: 40px;
	font-size: 24px;
	font-weight: bold;
	text-align: center;
	border: 2px dashed #000;

	input,
	div {
		width: 100%;
		padding: 30px 30px 20px 30px;
	}

	p {
		font-size: 18px;
	}

	button {
		margin-top: -40px;
	}

	&.on-hover {
		color: #fff;
		background-color: rgba(0, 0, 0, 0.5);
	}
}
