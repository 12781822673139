#page-stats {
	.container-fluid {
		padding-right: 0;
		padding-left: 0;
	}

	footer {
		margin-top: 0;
	}

	.total-stats-header {
		max-width: 100%;
		padding-top: 20px;
		padding-bottom: 20px;
		font-size: 24px;
		font-weight: 700;
		color: #000;
		text-align: center;

		@media (max-width: 768px) {
			max-width: 100%;
			font-size: 18px;
		}
	}
}
