@import "../ui/common.scss";

#request-interaction {
	background: #fff;

	.header-img {
		margin: 40px auto 0 auto;
		max-width: 400px;
		display: block;
	}

	.btn-rounded {
		margin-right: 15px;
		color: #fff;
		letter-spacing: 1px;
		border: 1px solid #fff;

		&.active {
			color: $secondary !important;
			background: #fff;
		}
	}
}
