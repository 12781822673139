@import "../ui/common.scss";

#home-page-new {
	.navbar {
		height: 250px;
		align-items: flex-start;
		padding-top: 30px;
	}

	.igloo-login-nav {
		justify-content: flex-end;
		width: 100%;
		.btn-rounded {
			width: 130px;
			margin-right: 15px;
			color: #fff;
			letter-spacing: 1px;
			border: 1px solid #fff;

			&.active {
				color: $secondary !important;
				background: #fff;
			}
		}
	}

	.igloo-home-svg-wrap {
		margin-top: -93px;
		margin-bottom: 80px;
	}

	.igloo-home-category-list {
		margin-bottom: 50px;
		padding-left: 35px;
		padding-right: 35px;

		.igloo-home-categories-card {
			border: 1px #d5d5d5 solid;
			border-radius: 10px;
			align-self: center;
			padding: 40px;
		}

		h3,
		.category-description {
			text-align: left;
			padding-left: 15px;
		}

		.category-description {
			margin-bottom: 40px;
			margin-top: 15px;
			font-size: 20px;
		}

		h3 {
			font-weight: 600;
		}

		.category-interaction-items {
			text-align: center;

			.category-interaction-item {
				img {
					margin-bottom: 20px;
					max-width: 90%;
				}

				.interaction-name {
					font-size: 18px;
				}
			}
		}
	}

	img[src*="iglooo_screen.svg"].card-img-top {
		filter: drop-shadow(0 0 0.75rem rgba(0, 0, 0, 0.5));
		z-index: 1;
	}
}
