@import "../ui/common.scss";

#igloo-subscription {
	.igloo-details {
		width: 50%;
		height: 100%;
		margin-top: 100px;
		padding: 40px;
		margin-bottom: 100px;
		background: #fff;
		border-bottom: 5px solid $primary;

		.igloo-th {
			color: #495057;
			background-color: #e9ecef;
			border-color: #dee2e6;
		}
	}
}

.igloo-card {
	box-sizing: border-box;
	border: 1px solid #ebebeb;
	width: 100%;
	display: inline-flex;
	flex-direction: column;
	margin-bottom: 1rem;
}

#card_number {
	width: 100%;
	position: inherit;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}

.hlTfmo.hlTfmo {
	width: 100%;
}

#exp_date {
	width: 100%;
	border: 0px;
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}

#cvc {
	width: 100%;
	margin-right: 10px;
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}
