@import "../ui/common.scss";

$whitecolor: #ffffff;

$blackcolor: black;

body {
	overflow-x: hidden;
}

#premium-page {
	.igloo-footer.mt-5 {
		margin-top: 0px !important;
	}
	.main-nav {
		background: transparent;
	}
	.igloo-premium {
		background-size: cover;
		background-image: url("/images/premium-bg.jpg");
		height: 100%;

		h1 {
			font-size: 92px;
			font-weight: 700;
			padding-top: 55px;
			color: $whitecolor;

			@media (max-width: 1024px) {
				font-size: 38px;
				line-height: 38px;
			}
		}

		.igloo-premium-body {
			text-align: left;

			.igloo-premium-first {
				color: $blackcolor;

				.igloo-premium-firstheader {
					font-weight: bold;
					font-size: 38px;
				}

				.igloo-premium-terms {
					color: $whitecolor;
					font-weight: bold;
					font-size: 15px;
					margin-top: 150px;
					margin-bottom: 40px;
				}
			}
		}

		.igloo-premium-buttons {
			margin-top: 35px;
			padding-top: 40px;
			a {
				padding: 15px 60px;
			}
		}
	}

	.btn-rounded {
		margin-right: 15px;
		color: #ffffff;
		border: 1px solid #ffffff;

		&.active {
			color: $primary;
			background: #142263;
		}
	}

	.btn-rounded-success {
		padding: 10px 30px;
		font-size: 14px;
		font-weight: 300;
		border: none;
		border-radius: 30px;
		width: 130px;
		margin-right: 15px;
		color: #ffffff;
		border: 1px solid #3fb86b;
		background: #3fb86b;

		&.active {
			color: $primary;
			background: #3fb86b;
		}
	}

	.btn-getfree {
		padding: 10px 10px;
		border: none;
		border-radius: 30px;
		margin-right: 15px;

		font-weight: bold;
		color: black;
		border: 1px solid black;
		background: white;

		&.active {
			color: $primary;
			background: #3fb86b;
		}
	}

	.btn-getpremium {
		padding: 10px 10px;
		border: none;
		border-radius: 30px;
		margin-right: 15px;

		font-weight: bold;
		color: white;
		border: 1px solid #3fb86b;
		background: #3fb86b;

		&.active {
			color: $primary;
			background: #3fb86b;
		}
	}

	.igloo-premium-why {
		color: $blackcolor;
		font-weight: bold;
		text-align: center;
		font-size: 48px;
		margin-top: 60px;
	}

	.igloo-premium-benefits {
		text-align: center;
		margin-top: 70px;

		img {
			max-width: 210px;
		}
	}

	.igloo-premium-benefit {
		font-weight: bold;
		padding-top: 15px;
		font-size: 22px;
	}

	.igloo-premium-benefitsub {
		display: block;
		padding-top: 10px;
		font-size: 12px;
	}

	.igloo-premium-plansdiv {
		background-color: #cce0f8;
		padding-bottom: 100px;
		margin-top: 100px;
		scroll-behavior: smooth;
	}

	.igloo-premium-planheading {
		margin-top: 90px;
		font-weight: bold;
		text-align: center;
		font-size: 25px;
	}

	.igloo-premium-plans {
		margin-top: 50px;

		.igloo-premium-plan {
			@media (max-width: 768px) {
				margin-bottom: 20px;
			}
		}
	}

	.igloo-premium-plansub {
		font-size: 10px;
	}

	.igloo-premium-price {
		font-size: 30px;
	}

	.igloo-premium-school {
		padding-top: 10px;
		font-size: 20px;
	}

	.igloo-premium-learn {
		color: green;
		font-weight: bold;
	}
}
