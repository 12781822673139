.igloo-dropdown-with-caret {
	.dropdown-toggle {
		&::before {
			display: none;
			border: none !important;
		}
	}

	.dropdown-menu {
		padding: 0.5rem 0.7rem;
		background: #fff;
		border: 4px solid #fff;
		border-radius: 0;
		-webkit-box-shadow: -2px -1px 21px 0 rgba(0, 0, 0, 0.38);
		-moz-box-shadow: -2px -1px 21px 0 rgba(0, 0, 0, 0.38);
		box-shadow: -2px -1px 21px 0 rgba(0, 0, 0, 0.38);

		.dropdown-item {
			padding: 0.1rem 0.2rem;
			font-size: 16px;
		}

		&::after,
		&::before {
			position: absolute;
			top: 15px;
			left: 100%;
			width: 0;
			height: 0;
			pointer-events: none;
			content: " ";
			border: solid transparent;
		}

		&::after {
			margin-top: -8px;
			border-color: rgba(255, 255, 255, 0);
			border-width: 8px;
			border-left-color: #fff;
		}

		&::before {
			margin-top: -14px;
			border-color: rgba(255, 255, 255, 0);
			border-width: 14px;
			border-left-color: #fff;
		}
	}
}
