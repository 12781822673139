@import "../ui/common.scss";

#page-features {
	.igloo-features-section {
		padding-top: 40px;
		h4 {
			margin-bottom: 30px;
			font-size: 36px;
			color: #142263;
			text-align: center;
		}

		.features {
			padding-top: 50px;
			padding-bottom: 50px;

			@media (max-width: 768px) {
				padding-top: 20px;
				padding-bottom: 20px;
			}

			strong {
				display: block;
				margin-bottom: 20px;
				color: #636362;
			}

			p {
				max-width: 320px;
				margin: 0 auto;
				color: #636362;
			}

			div {
				min-height: 325px;
				max-height: 325px;
				margin-bottom: 40px;

				@media (max-width: 768px) {
					min-height: 250px;
					margin-bottom: 10px;
				}
			}

			img {
				display: block;
				max-height: 75px;
				margin: 0 auto;
				margin-bottom: 25px;
			}
		}
	}
}
