@import "ui/common.scss";

body {
	background: #f0f4f7;
}

.truncate {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.media-storage-wrapper {
	width: 400px;
}

.display-flex {
	display: flex;
}

.flex-end {
	align-items: flex-end;
}

.common-wrapper {
	height: 100%;
	width: 50%;
	margin-top: 100px;

	@media (max-width: 768px) {
		width: 100%;
		margin-top: 0px;
		padding: 0;
	}

	.frow {
		background: #fff;
		padding: 40px;
		border-bottom: 5px solid $primary;
		margin-bottom: 100px;

		@media (max-width: 768px) {
			padding: 10px;
			padding-bottom: 40px;
			width: 100%;
			margin-bottom: 0;
			margin-right: 0px;
			margin-left: 0px;
		}
	}

	.pwd-wrapper {
		margin-top: 20px;
	}

	h1 {
		font-weight: 300;
		font-size: 2.5vw;
		margin-bottom: 40px;
		color: #666;

		@media (max-width: 768px) {
			font-size: 26px;
			margin-top: 20px;
		}
	}

	input[type="text"],
	input[type="password"] {
		border: 1px solid #ebebeb;
		height: 45px;
		font-size: 16px;
		padding: 10px;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
	}

	.button button {
		border: none;
		color: #fff;
		padding: 10px 30px;
		float: right;
		margin-top: 20px;
	}

	label {
		font-size: 1rem;
		color: #000000;
		margin-bottom: 10px;
		display: block;
	}
}
